import { storeToRefs } from 'pinia'
import { createHead, useHead, useSeoMeta, useServerSeoMeta, useServerHead } from 'unhead'
import { renderSSRHead } from '@unhead/ssr'
import { usePagesStore } from '#root/stores/storePages.js'
import { useVWOStore } from '#root/stores/storeVWO.js'
import {
  useGetContentPage,
  useGetContent,
  useGetContentOptions
} from '#root/components/composables/getContent'
import { customCss } from '#root/utils/helpers/helperVwo.js'
import { optimise } from '#root/utils/helpers/helperImage.js'

const faqContentMap = (content) => {
  const faqBlock = content?.elements?.page_blocks?.linkedItems.find(
    (b) => b.system.type === 'faq_block_model'
  )
  if (!faqBlock) return false
  const mapSetQuestions = faqBlock.elements.main_question_set.linkedItems.map((item) => {
    return item.elements.accordion_items.linkedItems.map((item) => {
      return {
        '@type': 'Question',
        name: item.elements.question.value,
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            item.elements.answer.value && item.elements.answer.value !== ''
              ? item.elements.answer.value.replace(/(<([^>]+)>)/gi, '')
              : ''
        }
      }
    })
  })
  const addionalQuestions = faqBlock.elements.additional_question_sets.linkedItems.map((item) => {
    return item.elements.accordion_items.linkedItems.map((item) => {
      return {
        '@type': 'Question',
        name: item.elements.question.value,
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            item.elements.answer.value && item.elements.answer.value !== ''
              ? item.elements.answer.value.replace(/(<([^>]+)>)/gi, '')
              : ''
        }
      }
    })
  })
  return [...mapSetQuestions, ...addionalQuestions].flat()
}

const getIdDomain = () => {
  if (import.meta.env.SSR) {
    return process.env.BASE_URL
  } else {
    return window.location.origin
  }
}

const breadcrumbsMap = (breadcrumbs) => {
  const url = getIdDomain()

  const home = {
    '@type': 'ListItem',
    position: 1,
    item: {
      '@id': url,
      name: 'Home'
    }
  }

  const items = breadcrumbs.map((item, index) => ({
    '@type': 'ListItem',
    position: index + 2,
    item: {
      '@id': url.replace(/\/$/, '') + item.elements.url.value,
      name: item.elements.title.value
    }
  }))

  items.unshift(home) //Add home to beginning
  if (!items) {
    return null
  }
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: items
  }
}

const homeSchemaMap = (content) => {
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'Organization'
  }
  const schemaContent = {
    name: 'schema_markup_name.value',
    url: 'schema_markup_url.value',
    logo: 'schema_markup_logo.value',
    sameAs: 'schema_markup_sameas.value'
  }

  Object.entries(schemaContent).forEach(([key, path]) => {
    const value = useGetContent(content, path, false)
    if (value) schema[key] = value
  })

  return schema
}

const articleSchemaMap = (content) => {
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'Article'
  }

  // Define base map for schema properties
  const schemaContent = {
    mainEntityOfPage: 'mainentityofpage.value',
    headline: 'headline.value',
    description: 'description.value'
  }

  // Dynamically populate schema with base map
  Object.entries(schemaContent).forEach(([key, path]) => {
    const value = useGetContent(content, path, false)
    if (value) schema[key] = value
  })

  // Create about schema
  const aboutValue = useGetContent(content, 'about.value', false)
  if (aboutValue) {
    schema.about = {
      '@type': 'Thing',
      name: aboutValue
    }
  }

  // Create author schema
  const author = {}
  const authorName = useGetContent(
    content,
    'global_schema_markup.linkedItems[0].elements.author_name.value',
    false
  )
  const authorUrl = useGetContent(
    content,
    'global_schema_markup.linkedItems[0].elements.author_url.value',
    false
  )
  const authorLogo = useGetContent(
    content,
    'global_schema_markup.linkedItems[0].elements.author_logo_url.value',
    false
  )

  if (authorName) author.name = authorName
  if (authorUrl) author.url = authorUrl
  if (authorLogo) author.logo = authorLogo

  if (Object.keys(author).length > 0) {
    author['@type'] = 'Organization'
    schema.author = author
  }

  // Create publisher schema
  const publisher = {}
  const publisherName = useGetContent(
    content,
    'global_schema_markup.linkedItems[0].elements.publisher_name.value',
    false
  )
  const publisherUrl = useGetContent(
    content,
    'global_schema_markup.linkedItems[0].elements.publisher_url.value',
    false
  )
  const publisherLogo = useGetContent(
    content,
    'global_schema_markup.linkedItems[0].elements.publisher_logo_url.value',
    false
  )

  if (publisherName) publisher.name = publisherName
  if (publisherUrl) publisher.url = publisherUrl
  if (publisherLogo) publisher.logo = publisherLogo

  if (Object.keys(publisher).length > 0) {
    publisher['@type'] = 'Organization'
    schema.publisher = publisher
  }

  // Create image schema
  const imageUrl = useGetContent(
    content,
    'basic_info.linkedItems[0].elements.featured_image.value[0].url',
    false
  )
  if (imageUrl) {
    schema.image = {
      '@type': 'ImageObject',
      url: imageUrl,
      width: 1024,
      height: 512
    }
  }

  return schema
}

const pageSchemaMap = (content) => {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Product'
  }

  // Define a map of schema keys to content paths
  const schemaContent = {
    name: 'seo_product_schema__name.value',
    image: 'seo_product_schema__image.value',
    url: 'seo_product_schema__url.value',
    description: 'seo_product_schema__description.value',
    aggregateRating: 'seo_product_schema__aggregate_rating.value',
    bestRating: 'seo_product_schema__best_rating.value',
    ratingCount: 'seo_product_schema__rating_count.value'
  }

  // Dynamically populate the schema object
  Object.entries(schemaContent).forEach(([key, path]) => {
    const value = useGetContent(content, path, false)
    if (value) schema[key] = value
  })

  // Create brand schema
  const brandName = useGetContent(content, 'seo_product_schema__brand_name.value', false)
  if (brandName) {
    schema.brand = {
      '@type': 'Brand',
      name: brandName
    }
  }

  // Create review schema
  const reviews = useGetContent(content, 'seo_product_schema__reviews.linkedItems', false)
  // console.log(reviews)
  if (reviews.length && Array.isArray(reviews)) {
    schema.review = reviews.map((review) => {
      return {
        '@type': 'Review',
        reviewRating: {
          '@type': 'Rating',
          ratingValue: review.elements?.review_rating?.value || 5
        },
        author: {
          '@type': 'Person',
          name: review.elements?.review_author?.value || 'Anonymous'
        }
      }
    })
  }

  return schema
}

export const useHeadCreatorSSR = async (pageContext) => {
  const vwoStore = useVWOStore()
  const { vwoActivatedCampaigns, vwoSettingsFileCampaigns } = storeToRefs(vwoStore)
  const pageStore = usePagesStore()
  const head = createHead()
  const {
    title,
    description,
    noIndex,
    canonical,
    og,
    alternateUrl,
    alternateTitle,
    alternateLang,
    insightAppSecValidation
  } = pageStore.head
  const { pageContent } = pageStore
  const faqContent = faqContentMap(pageContent)
  const breadcrumbs = breadcrumbsMap(useGetContentPage('breadcrumb.linkedItems', []))
  const headCSS = useGetContentOptions('head_css')
  const articleSchema = articleSchemaMap(pageContent)
  const pageSchema = pageSchemaMap(pageContent)
  const homeSchema = homeSchemaMap(pageContent)
  const bannerImage = bannerImageMap(pageContent)

  useServerHead({
    title: title,
    meta: [
      {
        name: 'description',
        content: description
      },
      {
        name: 'format-detection',
        content: 'address=no, date=no, email=no, telephone=no'
      },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1.0'
      },
      {
        charset: 'UTF-8'
      },
      {
        name: 'insight-app-sec-validation',
        content: insightAppSecValidation
      },
      {
        name: 'robots',
        hid: 'robots',
        content: noIndex ? 'noindex, follow' : 'index, follow'
      },
      ...(pageContent?.elements?.exit_warning?.value.length
        ? [{ name: 'exit-warning', content: 'enable', key: 'exit-warning' }]
        : [{ name: 'exit-warning', content: 'disable', key: 'exit-warning' }])
    ],
    link: [
      {
        rel: 'icon',
        type: 'image/x-icon',
        href: import.meta.env.DEV ? `/static/brands/${process.env.BRAND}/favicon.ico` : '/favicon.ico'
      },
      {
        rel: 'canonical',
        href: canonical
      },
      { rel: 'preconnect', href: 'https://assets-eu-01.kc-usercontent.com' },
      { rel: 'preconnect', href: 'https://cdn-ukwest.onetrust.com' },
      { rel: 'preconnect', href: 'https://widget.trustpilot.com/' },
      ...(pageContext.urlPathname.includes('funeral-costs-report')
        ? [
            {
              rel: 'stylesheet',
              id: 'bootstrap',
              type: 'text/css',
              href: 'https://assets-eu-01.kc-usercontent.com/8c39dc5d-eb4b-01ee-26b9-74e65a377656/27250f00-5d32-4beb-9510-ef29b2979b71/bootstrap.min.css'
            },
            {
              rel: 'stylesheet',
              type: 'text/css',
              id: 'oath',
              href: 'https://assets-eu-01.kc-usercontent.com/8c39dc5d-eb4b-01ee-26b9-74e65a377656/1040035c-ce00-458e-a459-935fa1dddb84/styles23.css'
            }
          ]
        : []),
      ...(pageContext.urlPathname.includes('funeral-costs-report-2022')
        ? [
            {
              rel: 'stylesheet',
              id: 'bootstrap',
              type: 'text/css',
              href: 'https://assets-eu-01.kc-usercontent.com/8c39dc5d-eb4b-01ee-26b9-74e65a377656/27250f00-5d32-4beb-9510-ef29b2979b71/bootstrap.min.css'
            },
            {
              rel: 'stylesheet',
              type: 'text/css',
              id: 'oath',
              href: 'https://assets-eu-01.kc-usercontent.com/8c39dc5d-eb4b-01ee-26b9-74e65a377656/b659bb88-bc70-41f5-a6f5-3b3b00a94cb0/styles.css'
            }
          ]
        : []),
      ...(bannerImage.desktop
        ? [
            {
              rel: 'preload',
              as: 'image',
              href: optimise(bannerImage.mobile),
              media: '(max-width: 767px)'
            },
            {
              rel: 'preload',
              as: 'image',
              href: optimise(bannerImage.tablet),
              media: '(min-width: 768px) and (max-width: 1023px)'
            },
            {
              rel: 'preload',
              as: 'image',
              href: optimise(bannerImage.desktop),
              media: '(min-width: 1024px)'
            }
          ]
        : []),
      alternateUrl
        ? {
            rel: 'alternate',
            title: alternateTitle,
            hreflang: alternateLang,
            href: alternateUrl
          }
        : null
    ],
    script: [
      ...(process.env.ONETRUST
        ? [
            {
              type: 'text/javascript',
              async: true,
              innerHTML: `
                window.dataLayer = window.dataLayer || []
                function gtag(){dataLayer.push(arguments);}
                const optanonConsent = document.cookie?.split(';').filter(function(item) {
                  return item.indexOf('OptanonConsent=') >= 0
                })[0] || ''
                const groups = decodeURIComponent(optanonConsent?.split('groups=')[1]?.split('&')[0]?.split(','))?.split(',') || []
                gtag('consent', 'default', {
                  ad_storage: groups.includes('C0004:1') ? 'granted' : 'denied',
                  analytics_storage:  groups.includes('C0002:1') ? 'granted' : 'denied',
                  ad_user_data: groups.includes('C0004:1') ? 'granted' : 'denied',
                  ad_personalization: groups.includes('C0004:1') ? 'granted' : 'denied',
                  functionality_storage: 'denied',
                  personalization_storage: 'denied',
                  security_storage: 'denied',
                })
              `
            },
            {
              src: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
              type: 'text/javascript',
              async: true,
              hid: 'onetrust-script',
              'data-domain-script': process.env.ONETRUST,
              key: 'onetrust-script'
            },
            {
              type: 'text/javascript',
              innerHTML: `function OptanonWrapper () {}`,
              async: true
            }
          ]
        : []),
      {
        type: 'text/javascript',
        defer: true,
        tagPosition: 'bodyClose',
        src: '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
      },
      ...(pageContent?.elements?.page_blocks?.value.includes('reviews_widget')
        ? [
            { src: process.env.FEEFO_URL, defer: true, tagPosition: 'bodyClose' },
            {
              src: 'https://widget.reviews.io/polaris/build.js',
              defer: true,
              tagPosition: 'bodyClose'
            }
          ]
        : []),
      ...(process.env.BUGHERD_API_KEY
        ? [
            {
              src: `https://www.bugherd.com/sidebarv2.js?apikey=${process.env.BUGHERD_API_KEY}`,
              async: true,
              body: false
            }
          ]
        : []),
      ...(pageContent?.system?.type.includes('article_template_model')
        ? [
            {
              key: 'page',
              type: 'application/ld+json',
              innerHTML: JSON.stringify(articleSchema)
            }
          ]
        : pageContent?.system?.type.includes('landing_page_template_model')
          ? [
              {
                key: 'page',
                type: 'application/ld+json',
                innerHTML: JSON.stringify(pageSchema)
              }
            ]
          : [
              {
                key: 'page',
                type: 'application/ld+json',
                innerHTML: JSON.stringify(homeSchema)
              }
            ]),
      ...(faqContent
        ? [
            {
              key: 'faq',
              type: 'application/ld+json',
              innerHTML: {
                '@context': 'https://schema.org',
                '@type': 'FAQPage',
                mainEntity: faqContent
              }
            }
          ]
        : []),
      {
        key: 'breadcrumbs',
        type: 'application/ld+json',
        innerHTML: JSON.stringify(breadcrumbs)
      }
    ],
    style: [
      // variant styling from VWO
      {
        type: 'text/css',
        id: 'vwo-s2s-styles',
        innerHTML: customCss(vwoActivatedCampaigns, vwoSettingsFileCampaigns, pageContext.urlPathname)
      },
      ...(headCSS?.value?.length
        ? [
            {
              type: 'text/css',
              id: 'head-css',
              innerHTML: headCSS.value
            }
          ]
        : [])
    ]
  })

  useServerSeoMeta({
    ogTitle: og.title,
    ogDescription: og.description,
    ogImage: og.image[0]?.url,
    ogUrl: og.url,
    ogSiteName: og.siteName,
    ogType: og.type,
    ogLocale: og.locale,
    ogVideo: og.video
  })

  return renderSSRHead(head)
}

export const useHeadPatch = async ({ env, urlPathname }) => {
  const head = createHead()
  const vwoStore = useVWOStore()
  const pageStore = usePagesStore()
  const { pageContent } = pageStore
  const { vwoActivatedCampaigns, vwoSettingsFileCampaigns } = storeToRefs(vwoStore)
  const { FEEFO_URL } = env
  const { title, description, noIndex, canonical, og } = pageStore.head
  const faqContent = faqContentMap(pageContent)
  const breadcrumbs = breadcrumbsMap(useGetContentPage('breadcrumb.linkedItems', []))
  const articleSchema = articleSchemaMap(pageContent)
  const pageSchema = pageSchemaMap(pageContent)
  const homeSchema = homeSchemaMap(pageContent)

  useHead({
    title: title,
    meta: [
      {
        name: 'description',
        content: description
      },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1.0'
      },
      {
        name: 'format-detection',
        content: 'address=no, date=no, email=no, telephone=no'
      },
      {
        charset: 'UTF-8'
      },
      {
        name: 'robots',
        hid: 'robots',
        content: noIndex ? 'noindex, follow' : 'index, follow'
      },
      ...(pageContent?.elements?.exit_warning?.value.length
        ? [{ name: 'exit-warning', content: 'enable', key: 'exit-warning' }]
        : [{ name: 'exit-warning', content: 'disable', key: 'exit-warning' }])
    ],
    link: [
      {
        rel: 'canonical',
        href: canonical
      },
      ...(urlPathname.includes('funeral-costs-report')
        ? [
            {
              rel: 'stylesheet',
              id: 'bootstrap',
              type: 'text/css',
              href: 'https://assets-eu-01.kc-usercontent.com/8c39dc5d-eb4b-01ee-26b9-74e65a377656/27250f00-5d32-4beb-9510-ef29b2979b71/bootstrap.min.css'
            },
            {
              rel: 'stylesheet',
              id: 'oath',
              type: 'text/css',
              href: 'https://assets-eu-01.kc-usercontent.com/8c39dc5d-eb4b-01ee-26b9-74e65a377656/1040035c-ce00-458e-a459-935fa1dddb84/styles23.css'
            }
          ]
        : []),
      ...(urlPathname.includes('funeral-costs-report-2022')
        ? [
            {
              rel: 'stylesheet',
              id: 'bootstrap',
              type: 'text/css',
              href: 'https://assets-eu-01.kc-usercontent.com/8c39dc5d-eb4b-01ee-26b9-74e65a377656/27250f00-5d32-4beb-9510-ef29b2979b71/bootstrap.min.css'
            },
            {
              rel: 'stylesheet',
              id: 'oath',
              type: 'text/css',
              href: 'https://assets-eu-01.kc-usercontent.com/8c39dc5d-eb4b-01ee-26b9-74e65a377656/b659bb88-bc70-41f5-a6f5-3b3b00a94cb0/styles.css'
            }
          ]
        : [])
    ],
    script: [
      ...(pageContent.elements?.page_blocks?.value.includes('reviews_widget')
        ? [
            { src: FEEFO_URL, async: false, body: false },
            { src: 'https://widget.reviews.io/polaris/build.js', async: false, body: false }
          ]
        : [{}]),
      ...(pageContent.system.type.includes('article_template_model')
        ? [
            {
              key: 'page',
              type: 'application/ld+json',
              innerHTML: JSON.stringify(articleSchema)
            }
          ]
        : pageContent.system.type.includes('landing_page_template_model')
          ? [
              {
                key: 'page',
                type: 'application/ld+json',
                innerHTML: JSON.stringify(pageSchema)
              }
            ]
          : [
              {
                key: 'page',
                type: 'application/ld+json',
                innerHTML: JSON.stringify(homeSchema)
              }
            ]),
      ...(faqContent
        ? [
            {
              key: 'faq',
              type: 'application/ld+json',
              innerHTML: {
                '@context': 'https://schema.org',
                '@type': 'FAQPage',
                mainEntity: faqContent
              }
            }
          ]
        : []),
      {
        key: 'breadcrumbs',
        type: 'application/ld+json',
        innerHTML: JSON.stringify(breadcrumbs)
      }
    ],
    style: [
      // variant styling from VWO
      {
        type: 'text/css',
        id: 'vwo-s2s-styles',
        innerHTML: customCss(vwoActivatedCampaigns, vwoSettingsFileCampaigns, urlPathname)
      }
    ]
  })

  useSeoMeta({
    ogTitle: og.title,
    ogDescription: og.description,
    ogImage: og.image[0]?.url,
    ogUrl: og.url,
    ogSiteName: og.siteName,
    ogType: og.type,
    ogLocale: og.locale,
    ogVideo: og.video
  })

  return head
}

const getImageUrl = (content, imageKey) =>
  useGetContent(
    content,
    `banner.linkedItems[0].elements.foreground_image.linkedItems[0].elements.${imageKey}.value[0].url`
  )

const bannerImageMap = (content) => ({
  desktop: getImageUrl(content, 'image'),
  tablet: getImageUrl(content, 'tablet_image'),
  mobile: getImageUrl(content, 'mobile_image')
})
